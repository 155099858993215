const palette = [
  '#dc2626',
  '#ea580c',
  '#d97706',
  '#ca8a04',
  '#65a30d',
  '#16a34a',
  '#059669',
  '#0d9488',
  '#0891b2',
  '#0284c7',
  '#2563eb',
  '#4f46e5',
  '#9333ea',
  '#c026d3',
  '#db2777',
  '#e11d48',
]

// Split into 4 groups
const groups = Array.from({ length: 4 }, (_, i) =>
  palette.filter((__, index) => index % 4 === i),
)

// Rearrange colors in palette2
const palette2 = groups.flat()

export default palette2

export const defaultPalette = [
  '#4E79A7', // Soft Indigo
  '#F28E2B', // Vivid Orange
  '#59A14F', // Forest Green
  '#E15759', // Warm Red
  '#EDC948', // Sunflower Yellow
  '#B07AA1', // Muted Purple
  '#76B7B2', // Teal
]
