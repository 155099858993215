'use client'

import React, { useEffect, useRef, useState } from 'react'

const FitHTML = ({ children, minSize, maxSize }) => {
  const [rendered, setRendered] = useState(false)
  const containerRef = useRef(null)
  const textRef = useRef(null)

  const adjustFontSize = () => {
    const container = containerRef.current
    const text = textRef.current

    let lowerBound = minSize || 8
    let upperBound = maxSize || container.clientHeight

    while (lowerBound <= upperBound) {
      const mid = Math.floor((lowerBound + upperBound) / 2)
      text.style.fontSize = `${mid}px`

      if (
        text.scrollHeight > container.clientHeight ||
        text.scrollWidth > container.clientWidth
      ) {
        upperBound = mid - 1
      } else {
        lowerBound = mid + 1
      }
    }

    text.style.fontSize = `${upperBound}px`
    setRendered(true)
  }

  useEffect(() => {
    adjustFontSize()
    window.addEventListener('resize', adjustFontSize)
    const interval = setInterval(adjustFontSize, 1000)

    return () => {
      window.removeEventListener('resize', adjustFontSize)
      clearInterval(interval)
    }
  }, [children])

  return (
    <div className="flex h-full w-full items-center" ref={containerRef}>
      <div
        className="w-full"
        style={{
          fontSize: 16,
          display: 'inline-block',
          visibility: rendered ? 'visible' : 'hidden',
        }}
        ref={textRef}
      >
        {children}
      </div>
    </div>
  )
}

export default FitHTML
