import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

import getDraftJSContent from 'utils/getDraftJSContent'

const options = {
  inlineStyleFn: (styles) => {
    const key = 'color-'
    const color = styles.filter((value) => value.startsWith(key)).first()

    if (color) {
      return {
        element: 'span',
        style: {
          color: color.replace(key, ''),
        },
      }
    }

    return {}
  },
  blockStyleFn: (block) => {
    const data = block.getData()
    let style = {}

    if (data.get('color')) style = { ...style, color: data.get('color') }
    if (data.get('text-align'))
      style = { ...style, textAlign: data.get('text-align') }

    return { style }
  },
}

const convertDraftToQuill = (content) => {
  if (content && content.startsWith('<')) {
    return content
  }

  if (content && content.startsWith('{')) {
    const editorState = content
      ? EditorState.createWithContent(getDraftJSContent(content))
      : EditorState.createEmpty()
    const html = stateToHTML(editorState.getCurrentContent(), options)

    const newHtml = html
      .replace(/<p>/g, '<h1>')
      .replace(/<p style="">/g, '<h1>')
      .replace(/<\/p>/g, '</h1>')
      .replace(
        /<p style="text-align: center">/g,
        '<h1 class="ql-align-center">',
      )
      .replace(/<p style="text-align: right">/g, '<h1 class="ql-align-right">')
      .replace(
        /<p style="text-align: justify">/g,
        '<h1 class="ql-align-justify">',
      )

    // replace p with h1
    return newHtml
  }

  return content
}

export default convertDraftToQuill
