import { Tab } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Tabs({ tabs, tab, onChange }) {
  return (
    <div className="w-full sm:px-0">
      <Tab.Group
        selectedIndex={tab && tabs.findIndex((tabItem) => tabItem.key === tab)}
        onChange={(index) => onChange(tabs[index].key)}
      >
        <Tab.List className="flex space-x-1 rounded-lg bg-black bg-opacity-5 p-1">
          {tabs.map((tabItem) => (
            <Tab
              key={tabItem.key}
              className={({ selected }) =>
                classNames(
                  'w-full rounded py-2.5 text-sm font-medium leading-5',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white text-blue-600 shadow'
                    : 'text-gray-900 hover:bg-white/[0.12] hover:text-blue-600',
                )
              }
            >
              {tabItem.title}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  )
}
