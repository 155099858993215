import { ContentState, convertFromHTML,convertFromRaw } from 'draft-js'

const getContentFromJSON = (content) => {
  try {
    return convertFromRaw(JSON.parse(content))
  } catch (error) {
    return null
  }
}

const getContentFromObject = (content) => {
  try {
    return convertFromRaw(content)
  } catch (error) {
    return null
  }
}

const getContentFromString = (content) => {
  try {
    return ContentState.createFromText(content)
  } catch (error) {
    return null
  }
}

const getContentFromHTML = (content) => {
  try {
    const blocksFromHTML = convertFromHTML(content)
    return ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    )
  } catch (error) {
    return null
  }
}

const getDraftJSContent = (content) =>
  getContentFromJSON(content) ||
  getContentFromObject(content) ||
  getContentFromHTML(content) ||
  getContentFromString(content)

export default getDraftJSContent
