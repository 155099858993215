import React, { useEffect, useRef, useState } from 'react'
import { Textfit } from 'react-textfit'

const TextfitResponsive = ({ min, max, ...props }) => {
  const ref = useRef(null)
  const [maxWidth, setMaxWidth] = useState()
  const [maxHeight, setMaxHeight] = useState()
  const [realMin, setRealMin] = useState(min)
  const [realMax, setRealMax] = useState(max)

  useEffect(() => {
    const handleResize = () => {
      const rect = ref.current.getBoundingClientRect()
      setMaxWidth(rect.width)
      setMaxHeight(rect.height)

      if (window.outerWidth < 768) {
        setRealMin(12)
        setRealMax(24)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (window.outerWidth < 768) {
      setRealMin(12)
      setRealMax(24)
    }
  }, [min, max])

  return (
    <div ref={ref} className="h-full w-full">
      <Textfit
        min={realMin}
        max={realMax}
        {...props}
        style={{ maxWidth, maxHeight }}
      />
    </div>
  )
}

export default TextfitResponsive
