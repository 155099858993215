import React, { useEffect, useRef, useState } from 'react'

import FitHTML from 'components/FitHTML'
import Typograf from 'components/Typograf'
import convertDraftToQuill from 'utils/convertDraftToQuill'

const SlideContent = ({
  content: contentProp,
  placeholder,
  formatting = true,
}) => {
  const [maxSize, setMaxSize] = useState(100)
  const ref = useRef(null)
  const content = convertDraftToQuill(contentProp) || `<p>${placeholder}</h1>`

  const adjustFontSize = () => {
    if (!ref.current) return

    const parent = ref.current.closest('.slide-content')

    if (!parent) return

    const parentHeight = parent.clientHeight
    setMaxSize(parentHeight / 15)

    if (window.outerWidth < 768) {
      setMaxSize(24)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', adjustFontSize)

    return () => {
      window.removeEventListener('resize', adjustFontSize)
    }
  }, [])

  useEffect(() => {
    adjustFontSize()
  }, [ref.current])

  if (!formatting) {
    return <div dangerouslySetInnerHTML={{ __html: content }} />
  }

  return (
    <div className="ql-content absolute left-0 top-0 h-full w-full" ref={ref}>
      <FitHTML maxSize={maxSize} minSize={16}>
        <Typograf text={content} />
      </FitHTML>
    </div>
  )
}

export default SlideContent
