import React, { useEffect, useState } from 'react'
import classnames from 'classnames'

const Input = React.forwardRef(
  (
    {
      disabled,
      className = '',
      maxLength,
      size = 'base',
      type = 'text',
      value,
      onChange,
      ...props
    },
    ref,
  ) => {
    const [tempValue, setTempValue] = useState(value)

    useEffect(() => {
      setTempValue(value)
    }, [value])

    const defaultClassName =
      'form-input block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 bg-background border-default'

    const sizeClassNames = {
      xs: 'px-1 text-xs',
      sm: 'px-2 py-1 text-sm',
      base: 'px-2 py-2',
      lg: 'px-3 py-3 text-lg',
      xl: 'px-4 py-4 text-xl',
    }[size]

    const disabledClassNames = disabled ? 'bg-gray-100 cursor-not-allowed' : ''

    const componentsClassName = classnames(
      disabledClassNames,
      defaultClassName,
      sizeClassNames,
      className,
    )

    const handleChange = (event) => {
      if (typeof onChange === 'function') {
        onChange(event)
      }
      setTempValue(event.target.value)
    }

    const Component = type === 'textarea' ? 'textarea' : 'input'

    return (
      <div className="relative w-full">
        <Component
          ref={ref}
          className={componentsClassName}
          disabled={disabled}
          type={type}
          value={tempValue !== undefined ? tempValue : value}
          onChange={handleChange}
          maxLength={maxLength}
          {...props}
        />
        {maxLength && (
          <div className="absolute bottom-0 right-1 text-xs text-gray-500">
            {tempValue?.length || 0}/{maxLength}
          </div>
        )}
      </div>
    )
  },
)

Input.displayName = 'Input'

export default Input
