import React from 'react'
import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart as ChartJS,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  SubTitle,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { defaultPalette } from 'const/palette'
import { Bar } from 'react-chartjs-2'

import ChartjsPluginLabels from 'utils/chartjs-plugin-labels'

function splitter(str, l) {
  const strs = []
  while (str.length > l) {
    let pos = str.substring(0, l).lastIndexOf(' ')
    pos = pos <= 0 ? l : pos
    strs.push(str.substring(0, pos))
    let i = str.indexOf(' ', pos) + 1
    if (i < pos || i > pos + l) i = pos
    // eslint-disable-next-line no-param-reassign
    str = str.substring(i)
  }
  strs.push(str)
  return strs
}

ChartJS.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  ChartDataLabels,
  ChartjsPluginLabels,
)

ChartJS.defaults.font.size = 20
ChartJS.defaults.font.weight = 500

const ChartBar = ({ valueFormat, textColor = '#666', data, labels, theme }) => {
  const palette = theme?.palette || defaultPalette
  const hasImages = labels.some((label) => label.imageUrl)

  let params = {
    fontSizeRatio: 50,
    maxLength: 15,
  }

  if (data.length > 10) {
    params = {
      fontSizeRatio: 100,
      maxLength: 20,
    }
  }

  const images = labels.map((label) => {
    if (!label.imageUrl) {
      return null
    }

    const scaleImageSize = ({ width, height, maxWidth, maxHeight }) => {
      const ratio = Math.min(maxWidth / width, maxHeight / height)
      return {
        width: width * ratio,
        height: height * ratio,
      }
    }

    const maxSize = 80

    const size = scaleImageSize({
      width: label.width,
      height: label.height,
      maxWidth: maxSize,
      maxHeight: maxSize,
    })

    return {
      src: label.imageUrl,
      width: size.width,
      height: size.height,
    }
  })

  const options = {
    responsive: true,
    layout: {
      padding: {
        top: hasImages ? 100 : 40,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: textColor, // Change this to the color you want
        },
        border: {
          color: textColor,
        },
        ticks: {
          color: textColor,
          font(context) {
            const { width } = context.chart
            let size = Math.round(width / params.fontSizeRatio)
            size = Math.max(14, size)

            if (labels?.length > 6) {
              size = 14
            }

            return {
              size,
            }
          },
          autoSkip: false,
          callback: (value) => {
            const label = labels[value].text
            if (label == null) {
              return ''
            }
            return splitter(label, params.maxLength)
          },
        },
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      labels: [
        {
          render: 'image',
          images,
          position: 'outside',
          textMargin: 5,
        },
      ],
    },
  }

  options.plugins.datalabels = {
    color(context) {
      const hasImage = labels[context.dataIndex].imageUrl
      return hasImage ? '#fff' : context.dataset.backgroundColor
    },
    anchor(context) {
      const hasImage = labels[context.dataIndex].imageUrl
      return hasImage ? 'center' : 'end'
    },
    align(context) {
      const hasImage = labels[context.dataIndex].imageUrl
      return hasImage ? 'center' : 'top'
    },
    font: {
      size: 32,
      weight: 'bold',
    },
    formatter: (value, ctx) => {
      if (valueFormat === 'percent') {
        let sum = 0
        const dataArr = ctx.chart.data.datasets[0].data
        dataArr.forEach((val) => {
          sum += val
        })
        return `${((value * 100) / sum || 0).toFixed(0)}%`
      }
      return Math.round(value)
    },
  }

  return (
    <Bar
      data={{
        labels,
        datasets: [
          {
            data,
            backgroundColor: palette,
          },
        ],
      }}
      options={options}
      width="100%"
      height="100%"
      style={{ maxWidth: '100%' }}
    />
  )
}

export default ChartBar
