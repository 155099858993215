import React from 'react'
import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart as ChartJS,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  SubTitle,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { defaultPalette } from 'const/palette'
import { Pie } from 'react-chartjs-2'

ChartJS.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  ChartDataLabels,
)

ChartJS.defaults.font.size = 20
ChartJS.defaults.font.weight = 500

const ChartPie = ({ valueFormat, textColor = '#666', data, labels, theme }) => {
  const palette = theme?.palette || defaultPalette
  const textLabels = labels?.map((label) => label.text)

  let params = {
    fontSizeRatio: 50,
    maxLength: 15,
  }

  if (data.length > 10) {
    params = {
      fontSizeRatio: 100,
      maxLength: 20,
    }
  }

  const images = labels.map((label) => {
    if (!label.imageUrl) {
      return null
    }

    const scaleImageSize = ({ width, height, maxWidth, maxHeight }) => {
      const ratio = Math.min(maxWidth / width, maxHeight / height)
      return {
        width: width * ratio,
        height: height * ratio,
      }
    }

    const maxSize = 60

    const size = scaleImageSize({
      width: label.width,
      height: label.height,
      maxWidth: maxSize,
      maxHeight: maxSize,
    })

    return {
      src: label.imageUrl,
      width: size.width,
      height: size.height,
    }
  })

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        title: {
          display: true,
          font: {
            size: 20,
          },
          padding: {
            left: 12000,
          },
        },
        labels: {
          color: textColor,
          font(context) {
            const { width } = context.chart
            const size = Math.round(width / params.fontSizeRatio)

            return {
              size,
            }
          },
          autoSkip: false,
        },
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        render: 'image',
        images,
      },
    },
  }

  options.plugins.datalabels = {
    color: '#fff',
    anchor(context) {
      const hasImage = labels[context.dataIndex].imageUrl
      return hasImage ? 'end' : 'center'
    },
    align(context) {
      const hasImage = labels[context.dataIndex].imageUrl
      return hasImage ? 'start' : 'center'
    },
    font: {
      size: 32,
      weight: 'bold',
    },
    formatter: (value, ctx) => {
      if (valueFormat === 'percent') {
        let sum = 0
        const dataArr = ctx.chart.data.datasets[0].data
        dataArr.forEach((val) => {
          sum += val
        })
        return `${((value * 100) / sum || 0).toFixed(0)}%`
      }
      return Math.round(value)
    },
  }

  return (
    <Pie
      data={{
        labels: textLabels,
        datasets: [
          {
            data: data?.map((item) => item || null),
            backgroundColor: palette,
          },
        ],
      }}
      options={options}
      width="100%"
      height="100%"
      style={{ maxWidth: '100%' }}
    />
  )
}

export default ChartPie
