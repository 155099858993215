import Typograf from 'typograf'

const tp = new Typograf({ locale: ['ru'] })

tp.setSetting('common/nbsp/beforeShortLastWord', 'lengthLastWord', 10)
tp.disableRule('common/punctuation/apostrophe')
tp.disableRule('common/punctuation/delDoublePunctuation')
tp.disableRule('common/punctuation/hellip')
tp.disableRule('common/punctuation/quote')
tp.disableRule('common/punctuation/quoteLink')
tp.disableRule('ru/punctuation/ano')
tp.disableRule('ru/punctuation/exclamation')
tp.disableRule('ru/punctuation/exclamationQuestion')
tp.disableRule('ru/punctuation/hellipQuestion')

const TypografComponent = ({ text = '' }) => {
  const tpText = tp.execute(text)
  return <div dangerouslySetInnerHTML={{ __html: tpText }} />
}

export default TypografComponent
